var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Details")]),
      _c(
        "gov-table",
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Organisation")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.organisation.name))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Title")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.event.title))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Start")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.startDateTimeStr))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("End")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.endDateTimeStr))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Summary")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.event.intro))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Description")
                  ]),
                  _c("gov-table-cell", {
                    domProps: {
                      innerHTML: _vm._s(_vm.toHtml(_vm.event.description))
                    }
                  })
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Is this event free?")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.isFree))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Fees text")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(
                      _vm._s(_vm.event.is_free ? "-" : _vm.event.fees_text)
                    )
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Fees web address")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.is_free ? "-" : _vm.event.fees_url))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Organiser name")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.organiser_name || "-"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Organiser phone")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.organiser_phone || "-"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Organiser email")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.organiser_email || "-"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Organiser web address")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.organiser_url || "-"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Booking title")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.booking_title || "-"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Booking summary")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.booking_summary || "-"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Booking url")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.booking_url || "-"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Booking button text")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.event.booking_cta || "-"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Show on home page")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.onHomepage))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Image")
                  ]),
                  _c("gov-table-cell", [
                    _c("img", {
                      staticClass: "ck-logo",
                      attrs: {
                        src: _vm.apiUrl(
                          "/organisation-events/" +
                            _vm.event.id +
                            "/image.png?v=" +
                            _vm.event.created_at
                        ),
                        alt: "Event image"
                      }
                    })
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Is this event virtual?")
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.isVirtual))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { scope: "row", top: "" } }, [
                    _vm._v("Location")
                  ]),
                  _vm.event.is_virtual
                    ? _c("gov-table-cell", [_vm._v("-")])
                    : _c(
                        "gov-table-cell",
                        [
                          _c("location-details", {
                            attrs: { location: _vm.event.location }
                          })
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }